/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { FC, useRef } from 'react';
import Slider from 'react-slick';
import { isMobileOnly } from 'react-device-detect';

import { useTranslation } from 'react-i18next';
import { Image } from '../../ui/image';
import pages from '../../../utils/pages';

import Button from '../../ui/button';
import { ImageGallery } from '../../ui/imageGallery';

const { getPageUrl } = pages;
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span
      className={`${className} custom_slick_arrow `}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <ImageGallery
        name="chevron_right_circle_black"
        alt="arrow next"
        className="arrow arrow-right"
      />
    </span>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span
      className={`custom_slick_arrow ${className}`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <ImageGallery
        name="chevron_left_circle_black"
        className="arrow arrow-left"
        alt="arrow left"
      />
    </span>
  );
}

const DefaultSlider: FC<{
  classes: string;
  children: any;
}> = ({ classes, children }) => {
  const slider = useRef<any>(null);
  const settings = {
    variableWidth: !isMobileOnly,
    adaptiveHeight: true,
    dots: false,
    autoplay: false,
    infinite: false,
    arrows: true,
    speed: 1000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    swipeToSlide: false,
    focusOnSelect: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  function previous(): void {
    if (slider.current) {
      slider.current.slickNext();
    }
  }

  function next(): void {
    slider.current.slickPrev();
  }
  return (
    <Slider
      {...settings}
      ref={(c) => (slider.current = c)}
      className={`${classes} addon_slider`}
    >
      {children}
    </Slider>
  );
};

const Addons: FC<{
  addons: any;
}> = ({ addons }) => {
  const { t } = useTranslation('general');
  const lang = t('key');
  const campaign_prices = addons.campaign_prices;
 console.log(campaign_prices, addons);

  const AddonCards = Object.keys(addons.tiles).map((key) => {
    const card = addons.tiles[key];
    const page = getPageUrl(card.key, t('key'));
  //  console.log(page, card.key, t('key'), page.url);


    if (card.title)
      return (
        <div
          key={key}
          className="column add-on-card card is-3 black is-yearly card-equal-height full-height mx-0"
        >
          <div className="inner full-height fill-height card-equal-height">
            <div className="price-card-head card-head">
              <ImageGallery
                name={card.image}
                alt="additional features"
                className="addon-image"
                height="116"
              />
              <h4
                className="title is-spaced text-black"
                dangerouslySetInnerHTML={{ __html: card.title }}
              />
            </div>
            <p className="textblock text-black">{card.text}</p>

            <ul className="price-features">
              {card.features &&
                Object.keys(card.features).map((i) => {
                  return (
                    <li key={`feature${i}`} className="feature">
                      <Image
                        name="check_black.svg"
                        className="inline-svg  "
                        alt="shore.com"
                      />
                      {card.features[i]}
                    </li>
                  );
                })}
            </ul>

            <span className="price-block">
              <span className="price price-period">
                {card.prefix && card.prefix}{' '}
              </span>
              <span className="price">{campaign_prices && campaign_prices[key]  ? campaign_prices[key] : card.price}</span>
              <span className="price price-period">/ {card.period}</span>
              {card['extra-note'] && (
                <p className="is-size-7 price extra-note">
                  {card['extra-note']}
                </p>
              )}
              {card.priceNotes && (
                <ul className="price-notes">
                  {Object.keys(card.priceNotes).map((note) => {
                    return (
                      <li key={`${note}`} className="note">
                        {card.priceNotes[note]}
                      </li>
                    );
                  })}
                </ul>
              )}
            </span>
            <div className="card-footer">
              {card.key && page && page.url && (
                <a
                  className={` inline-link  ${card.key === '' ? 'hide' : ''}`}
                  href={page.url}
                >
                  {t('learnMore')}
                  <Image
                    name="angle-right.svg"
                    alt="angle-right icon"
                    className="inline-icon angle inline"
                  />
                </a>
              )}
              {card.url && (
                <a className={` inline-link`} href={card.url}>
                  {t('learnMore')}
                  <Image
                    name="angle-right.svg"
                    alt="angle-right icon"
                    className="inline-icon angle inline"
                  />
                </a>
              )}
              {card.ctaKey && (
                <Button
                  to={getPageUrl(card.ctaKey, lang).url}
                  primary
                  size="inline"
                >
                  {card.ctaText}
                </Button>
              )}
            </div>
          </div>
        </div>
      );
    return null;
  });
  return (
    <section
      className="section background-new-grey add-ons slider-add-ons pt-5 mb-5  pb-3"
      id="slider-add-ons"
    >
      <div className="container is-boxed is-centered">
        <div className="section-head">
          <h3 className="title title-large section-title text-black has-text-centered">
            {addons.title}
          </h3>
          <h4 className="subtitle text-black has-text-centered">
            {addons.text}
          </h4>
        </div>
        <div className="section-content">
          <div className="columns is-centered">
            {AddonCards.length > 3 ? (
              <DefaultSlider classes="">{AddonCards}</DefaultSlider>
            ) : (
              AddonCards
            )}
          </div>
        </div>
        <div />
      </div>
      <div className="container is-boxed is-centered text-centered  small-note service-fee">
        <p className="small-note has-text-black">{addons.serviceFee}</p>
      </div>
    </section>
  );
};

export default Addons;
