import * as React from 'react';
import { FC } from 'react';
import { Image } from '../ui/image';
import { ImageGallery } from '../ui/imageGallery';
import TrustPilot from '../partials/widgets/TrustPilot';

const HeadlineSection: FC<{
  hero: any;
}> = ({ hero }) => {
  return (
    <section className="hero pricing-hero section pb-0 background-new-grey">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title title-large has-text-centered text-black is-marginless">
            {hero.title}
          </h1>
          {hero.subheadline && (
            <p className="subheadline">{hero.subheadline}</p>
          )}
          <div className="row benefits_row">
            {hero.benefits &&
              Object.keys(hero.benefits).map((key) => {
                return (
                  <span className="benefit mx-2">
                    <Image
                      name="check_black.svg"
                      className="inline-svg  "
                      alt="shore.com"
                    />
                    {hero.benefits[key]}
                  </span>
                );
              })}
          </div>
          <div className="columns trust-row is.centered mt-5  ">
            <div className=" mx-auto mt-5 trust-row-inner">
              <div className="trust-box">
                <TrustPilot />
              </div>
              <div className="trust-box">
                <ImageGallery
                  alt="trust icon"
                  className="trust-content"
                  name="shore_trust_seals_01_made_in_germany.png"
                  maxWidth="300"
                  height="225"
                  hero
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeadlineSection;
