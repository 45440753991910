/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { FC, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';

import { LocalizedPageContext, IColumnList } from '../utils/types';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import PricingTabs from '../components/partials/pricing/tabs';
import PricingCards from '../components/partials/pricing/cards';
import PricingTable from '../components/partials/pricing/table';
import PricingTableNew from '../components/partials/pricing/tables_new';

import { SectionMethod } from '../components/partials/pricing/method';
import Quote from '../components/partials/pricing/quote';
import Addons from '../components/partials/pricing/addonsSlider';
import EnterpriseCard from '../components/partials/pricing/enterpriseCard';
import SectionFaq from '../components/sections/faq-section';
import ConversionSection from '../components/sections/conversion';
import HeadlineSection from '../components/sections/headline-sections';
import Shop from '../components/partials/pricing/shop';
import RoiSection from '../components/sections/roi-section';
import Button from '../components/ui/button';
import pages, { getPageUrl } from '../utils/pages';

const Page: FC<{
  pageContext: LocalizedPageContext;
}> = ({ pageContext }) => {
  const { t } = useTranslation(['pricing', 'general']);
  const [defaultGroup, setDefaultGroup] = useState('booking');
  const [priceGroup, setPriceGroup] = useState('year');

  const [sticky, setSticky] = useState(false);
  const [sticky_table, setStickyTable] = useState(false);
  const ref = useRef();

  useEffect(() => {
    window.onscroll = () => {
      const box = document.querySelector('#pricing_packages_section');
      const bounding = box.getBoundingClientRect();

      if (window.pageYOffset > 1000 && bounding.bottom > 1000) setSticky(true);
      else setSticky(false);

      const tableOffset = isMobileOnly ? 1600 : 1600;
      if (bounding.top < -51 && bounding.bottom > 400) {
        setStickyTable(true);
      } else setStickyTable(false);
    };
  }, []);

  const { lang, alternateUrls } = pageContext;

  const groups = ['booking', 'pos'];
  const hero: any = t('hero', {
    returnObjects: true,
  });
  const cards: any = t('cards', {
    returnObjects: true,
  });
  const addons: any = t('addOns', {
    returnObjects: true,
  });
  const questions: IColumnList = t('faq.questions', {
    returnObjects: true,
  });
  const enterprise: IColumnList = t('enterprise', {
    returnObjects: true,
  });

  /*
  "campaign_prices":{
    "booking":{
      "month": "39,92€",
      "year": "31,92€"
    },
    "b&m":{
      "month": "55,92€",
      "year": "47,92€"
    },
    "pos":{
      "month": "47,92€",
      "year": "39,92€"
    },
    "aio":{
      "month": "95 ,92€",
      "year": "87,92€"
    },
    "pos+":"16€",
    "website": "52,00€",
    "app": "32,00€",
    "tse": "7,20€",
    "shop": "32,00€"
  },

      "campaign_prices": {
      "pos": "16€",
      "website": "52,00€",
      "app": "32,00€",
      "tse": "7,20€",
      "pay": "0€"
    },
  */
  return (
    <>
      <Layout
        pageContext={pageContext}
        navClasses={sticky_table ? 'hidden ' : ' '}
      >
        <SEO
          lang={lang}
          title={t('meta.title')}
          description={t('meta.description')}
          alternateUrls={alternateUrls}
        />
        <HeadlineSection hero={hero} />
        <PricingCards
          data={t}
          cards={cards}
          priceGroup={priceGroup}
          setPriceGroup={setPriceGroup}
        />
        {lang !== 'us' && <Addons addons={addons} />}

        {/*   <PricingTable defaultGroup={defaultGroup} /> */}
        <PricingTableNew
          sticky={sticky_table}
          ref={ref}
          defaultGroup={defaultGroup}
        />
        {lang !== 'fr' && lang !== 'en-US' && lang !== 'de-CH' && (
          <RoiSection lang={lang} classes="has-background-grey" />
        )}

        <EnterpriseCard data={enterprise} />
        {lang !== 'en' &&
          lang !== 'fr' &&
          lang !== 'en-US' &&
          lang !== 'de-CH' &&
          lang !== 'es' && <Shop data={t} title={t('store.title')} />}
        <Quote quote={t('quote.text')} />
        <SectionFaq
          classes={t('faq.classes')}
          title={t('faq.title')}
          questions={questions}
        />
        <ConversionSection />
      </Layout>
    </>
  );
};

export default Page;
