/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import { Image } from '../../ui/image';
import Button from '../../ui/button';

const PricingTableNew: FC<{
  defaultGroup: any;
  sticky: boolean;
  ref: any;
}> = ({ defaultGroup, sticky, ref }) => {
  const { t } = useTranslation('pricing_packages');
  const [showAll, setShowAll] = useState([0, 1]);
  const [keyIndex, setKeyIndex] = useState(0);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const key = urlParams.get('table');
    if (key) setShowAll([Number(key)]);
  }, []);

  const table: any = t('table', {
    returnObjects: true,
  });
  const table_header: any = t('table_header', {
    returnObjects: true,
  });

  const addons: any = t('addons', {
    returnObjects: true,
  });

  const showTable = (key: any) => {
    if (!showAll.includes(key)) {
      showAll.push(key);
      setShowAll(showAll);
    } else {
      const index = showAll.indexOf(key);
      showAll.splice(index, 1);
      setShowAll(showAll);
    }
    setKeyIndex(keyIndex + 1);
  };

  const table_row_title = (title?, status?) => <span>{title && title}</span>;
  return (
    <>
      <section
        className="section packages-table-section de"
        id="pricing_packages_section"
        ref={ref}
      >
        <div className="columns">
          <div className="column section-head">
            <h3 className="title title-medium has-text-centered text-black">
              {t('title')}
            </h3>
          </div>
        </div>

        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="table-responsive packages-table is-yearly">
                <div className={`${sticky && 'sticky_table_header'}  `}>
                  <div className="table_header container  packages-table-section">
                    <table className="table is-fullwidth heading_table">
                      <tbody>
                        <tr>
                          {Object.keys(table_header).map((key, i) => {
                            if (i === 0)
                              return (
                                <td className="text-black  pb-0 listing-item is-hidden-mobile" />
                              );

                            return (
                              <td
                                className={`${key} text-black pb-0 has-text-centered`}
                              >
                                {table_header[key].badge && (
                                  <span className="highlight">
                                    {table_header[key].badge}
                                  </span>
                                )}
                                <h4 className="text-black">
                                  {table_header[key].title}
                                </h4>
                              </td>
                            );
                          })}
                        </tr>
                      </tbody>
                    </table>
                    <table className="table is-fullwidth heading_table sub_heading">
                      <tbody>
                        <tr className=" is-hidden-desktop">
                          <td
                            colSpan={4}
                            className="text-black listing-item is-hidden-desktop hast-text-centered"
                          >
                            {table_header.first.subtitle}
                          </td>
                        </tr>
                        <tr>
                          {Object.keys(table_header).map((key, i) => {
                            if (i === 0)
                              return (
                                <td className="text-black listing-item is-hidden-mobile">
                                  {table_header[key].subtitle}
                                </td>
                              );
                            return (
                              <td
                                style={{ width: '180px' }}
                                className={`${key} text-black has-text-centered  ${
                                  i !== 1 && isMobileOnly ? ' show_icon ' : null
                                } `}
                              >
                                {i !== 1 && isMobileOnly
                                  ? ' ∞ '
                                  : table_header[key].subtitle}
                              </td>
                            );
                          })}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {Object.keys(table).map((key, i) => {
                  const { title, features } = table[key];
                  return (
                    <>
                      <table
                        className="table is-fullwidth ghost_table is-hidden-mobile"
                        id=""
                      >
                        <thead>
                          <tr>
                            {Object.keys(table_header).map((k, j) => {
                              if (j === 0)
                                return (
                                  <th
                                    className="listing-item"
                                    aria-label="Listing Item"
                                  />
                                );

                              return (
                                <th
                                  style={{ width: '180px' }}
                                  className={`${k}`}
                                  aria-label={`${table_header[k].title}`}
                                />
                              );
                            })}
                          </tr>
                        </thead>
                      </table>
                      <table
                        className={
                          `${showAll.includes(i) ? 'open' : 'closed'}` +
                          ' table is-fullwidth'
                        }
                        id={key}
                      >
                        <thead
                          onClick={() => showTable(i)}
                          onKeyDown={() => showTable(i)}
                        >
                          <tr>
                            {Object.keys(table_header).map((k, j) => {
                              if (j === 0)
                                return (
                                  <>
                                    <th
                                      className="listing-item  is-hidden-desktop  mobile_table_headline"
                                      colSpan={4}
                                    >
                                      {showAll.includes(i) ? (
                                        <Image
                                          name="minus.svg"
                                          className="inline-svg circle-minus  arrow inline "
                                          alt="shore.com"
                                        />
                                      ) : (
                                        <Image
                                          name="circle_plus_white.svg"
                                          className="inline-svg circle-plus  arrow inline "
                                          alt="shore.com"
                                        />
                                      )}
                                      {table_row_title(title)}
                                    </th>
                                    <th className="listing-item is-hidden-mobile">
                                      {showAll.includes(i) ? (
                                        <Image
                                          name="minus.svg"
                                          className="inline-svg circle-minus  arrow inline "
                                          alt="shore.com"
                                        />
                                      ) : (
                                        <Image
                                          name="circle_plus_white.svg"
                                          className="inline-svg circle-plus  arrow inline "
                                          alt="shore.com"
                                        />
                                      )}
                                      {table_row_title(title)}
                                    </th>
                                  </>
                                );

                              return (
                                <th
                                  style={{ width: '180px' }}
                                  className={`${k} is-hidden-mobile`}
                                  aria-label={`${table_header[k].title}`}
                                />
                              );
                            })}
                          </tr>
                        </thead>

                        <tbody>
                          {showAll.includes(i) &&
                            features &&
                            Object.keys(features).map((k) => {
                              const row = features[k];
                              return (
                                <>
                                  <tr className=" is-visible-mobile is-hidden-desktop">
                                    <td className="listing-item " colSpan={4}>
                                      <h4 className="name text-black">
                                        {row.title}
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr key={k} className={` base ${k}`}>
                                    <td className="listing-item is-hidden-mobile">
                                      <h4 className="name text-black">
                                        {row.title}
                                      </h4>
                                      <p> {row.subtitle}</p>
                                    </td>
                                    <td className="svg-icon has-text-centered booking">
                                      {row.booking && (
                                        <Image
                                          name="check_black.svg"
                                          className="inline-svg table-icon black"
                                          alt="shore.com"
                                        />
                                      )}
                                    </td>

                                    <td className="svg-icon has-text-centered b&m">
                                      {row['b&m'] && (
                                        <Image
                                          name="check_black.svg"
                                          className="inline-svg table-icon black"
                                          alt="shore.com"
                                        />
                                      )}
                                    </td>

                                    <td className="svg-icon has-text-centered pos">
                                      {row.pos && (
                                        <Image
                                          name="check_black.svg"
                                          className="inline-svg table-icon black false basic"
                                          alt="shore.com"
                                        />
                                      )}
                                      {!row.pos && row['pos+'] && (
                                        <p
                                          className="pos_addon"
                                          dangerouslySetInnerHTML={{
                                            __html: t('bookablePOS+'),
                                          }}
                                        />
                                      )}
                                    </td>
                                    <td className="svg-icon has-text-centered aio premium">
                                      {row.aio ? (
                                        <Image
                                          name="check_black.svg"
                                          className="inline-svg table-icon black false basic"
                                          alt="shore.com"
                                        />
                                      ) : (
                                        <Image
                                          name="close-x.svg"
                                          className="inline-svg table-icon grey close"
                                          alt="shore.com"
                                        />
                                      )}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </table>
                    </>
                  );
                })}

                <table
                  className="table is-fullwidth ghost_table last_table"
                  id=""
                >
                  <thead>
                    <tr>
                      <th className="listing-item" aria-label="Listing Item" />
                      <th className="booking" aria-label="Premium" />
                      <th className="b&m" aria-label="Premium" />
                      <th className="pos" aria-label="Plus" />
                      <th className="aio premium" aria-label="Basic" />
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
          <ul />
        </div>
      </section>
    </>
  );
};

export default PricingTableNew;
