/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { FC, useRef } from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import Button from '../../ui/button';
import { SwitchSecondary } from '../../ui/switch';
import { Image } from '../../ui/image';
import pages from '../../../utils/pages';
import { ImageGallery } from '../../ui/imageGallery';

const { getPageUrl } = pages;

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span
      className={`${className} custom_slick_arrow `}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <ImageGallery
        name="chevron_right_circle_black"
        alt="arrow next"
        className="arrow arrow-right"
      />
    </span>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span
      className={`custom_slick_arrow ${className}`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <ImageGallery
        name="chevron_left_circle_black"
        className="arrow arrow-left"
        alt="arrow left"
      />
    </span>
  );
}
const DefaultSlider: FC<{
  classes: string;
  children: any;
}> = ({ classes, children }) => {
  const slider = useRef<any>(null);
  const settings = {
    variableWidth: true,
    adaptiveHeight: false,
    dots: false,
    autoplay: false,
    infinite: false,
    arrows: true,
    speed: 1000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    swipeToSlide: true,
    focusOnSelect: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  function previous(): void {
    if (slider.current) {
      slider.current.slickNext();
    }
  }

  function next(): void {
    slider.current.slickPrev();
  }
  return (
    <Slider
      {...settings}
      /*  ref={(c) => (slider.current = c)} */
      className={`${classes} price_card_slider`}
    >
      {children}
    </Slider>
  );
};
interface IPrices {
  price: any;
  campaign_prices?: any;
  group: string;
  note?: string;
}

const Prices: FC<IPrices> = ({
  price = { month: 'N/A', period: 'N/A' },
  group = '',
  campaign_prices = null,
  note = '',
}) => {
  /*  "campaign_prices": {
    "booking": {
      "month": "42,42€",
      "year": "33,92€"
    },
    "b&m": {
      "month": "59,42€",
      "year": "50,92€"
    },
    "pos": {
      "month": "50,92€",
      "year": "42,42€"
    },
    "aio": {
      "month": "101,92€",
      "year": "93,42€"
    }
  }, */

  const campaign = typeof campaign_prices === 'object';
  if (campaign) console.log('new prices', group, campaign_prices);
  return (
    <div
      className={`price-block ${group} ${
        campaign && campaign_prices && campaign_prices[group]
          ? 'campaign_pricing'
          : 'normal_pricing'
      }`}
    >
      {campaign && campaign_prices && campaign_prices[group] ? (
        <>
          <span className="price price-old ">{price[group]}</span>
          <span className="price price-monthly">{campaign_prices[group]}</span>
        </>
      ) : (
        <>
          <span className="price price-old ">{price.month}</span>
          <span className="price price-monthly">{price[group]}</span>
        </>
      )}
      <span className="price price-period">/ {price.period}</span>

      <p className="is-size-7  extra-note">{note}</p>
    </div>
  );
};

Prices.defaultProps = {
  campaign_prices: null,
  note: '',
};

const PricingCards: FC<{
  cards: any;
  data: any;
  priceGroup: any;
  setPriceGroup: any;
}> = ({ cards, priceGroup, setPriceGroup, data }) => {
  const { t } = useTranslation('general');
  const lang = t('key');

  const campaign_prices: any = data('campaign_prices', {
    returnObjects: true,
  });

  return (
    <section
      id="price-cards-section"
      className="section pricing-cards-section background-new-grey pt-5"
    >
      <SwitchSecondary current={priceGroup} setPriceGroup={setPriceGroup} />
      <div className="price-cards is-boxed is-centered">
        <div className="container">
          {/* { priceGroup === 'year' && lang !== 'de' && lang !== 'de-CH' ? null : (
            <ImageGallery
              className="campaign-promo banner-img"
              height="136"
              maxWidth="165"
              name="shore_wiesn2024_sale_badge_01_pricing"
              alt="xmas sale"
            />
          ) }  */}
          <DefaultSlider classes="is-hidden-desktop">
            {/* Booking Card */}
            <div className="price-card full-height black booking_card mx-4">
              <div className="inner">
                <div className="price-card-head">
                  <h3 className="title is-spaced text-black">
                    {cards.booking.title}
                  </h3>
                </div>
                <p className=" is-spaced text-black description">
                  {cards.booking.text}
                </p>

                <Prices
                    price={cards.booking.price}
                    note={cards.booking['price-note']}
                    campaign_prices={campaign_prices.booking}
                    group={priceGroup}
                  />
                <Button
                  to={getPageUrl(cards.booking.key, lang).url}
                  className="purple cta"
                  primary
                  size="inline"
                >
                  {cards.booking.ctaText}
                </Button>

                {cards.booking.notes && (
                  <ul className="price-notes">
                    {Object.keys(cards.booking.notes).map((note) => {
                      return (
                        <li
                          key={`${note}`}
                          className="note"
                          dangerouslySetInnerHTML={{
                            __html: cards.booking.notes[note],
                          }}
                        />
                      );
                    })}
                  </ul>
                )}

                <ul className="price-features">
                  {Object.keys(cards.booking.features).map((feature, i) => {
                    return (
                      <li key={`${feature}`} className="feature_included">
                        <Image
                          name="check_black.svg"
                          className="inline-svg check_black "
                          alt="shore.com"
                        />
                        {cards.booking.features[feature]}
                      </li>
                    );
                  })}
                </ul>

                {cards.booking.subNotes && (
                  <ul className="price-subnotes">
                    {Object.keys(cards.subNotes).map((note, i) => {
                      return (
                        <li key={`${note}`} className="subNote">
                          {cards.subNotes[note]}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
            {/* AIO */}
            {cards.aio && (
              <div className="column full-height price-card black aio-card pt-0 mx-4">
                {cards.aio.badge && (
                  <div className="badge background-new-green text-white">
                    {cards.aio.badge}
                  </div>
                )}
                <div className="inner aio">
                  <div className="price-card-head">
                    <h3 className="title is-spaced text-black">
                      {cards.aio.title}
                    </h3>
                  </div>
                  <p className=" is-spaced text-black description">
                    {cards.aio.text}
                  </p>

                  <Prices
                    price={cards.aio.price}
                    note={cards.aio['price-note']}
                    campaign_prices={campaign_prices.aio}
                    group={priceGroup}
                  />
                  <Button
                    to={getPageUrl(cards.aio.key, lang).url}
                    className={cards.booking.ctaColor}
                    primary={cards.booking.ctaColor === 'purple'}
                    size="inline"
                  >
                    {cards.aio.ctaText}
                  </Button>

                  {cards.aio.notes && (
                    <ul className="price-notes green">
                      {Object.keys(cards.aio.notes).map((note) => {
                        return (
                          <li
                            key={`${note}`}
                            className="note"
                            dangerouslySetInnerHTML={{
                              __html: cards.aio.notes[note],
                            }}
                          />
                        );
                      })}
                    </ul>
                  )}

                  <ul className="price-features aio">
                    <li className="features_title headline">
                      {cards.aio.features_title}
                    </li>

                    {Object.keys(cards.aio.features).map((feature, i) => {
                      return (
                        <li key={`${feature}`} className="feature_included">
                          <Image
                            name="circle_plus_white.svg"
                            className="inline-svg circle-plus green"
                            alt="shore.com"
                          />
                          {cards.aio.features[feature]}
                        </li>
                      );
                    })}
                  </ul>

                  {cards.aio.subNotes && (
                    <ul className="price-subnotes">
                      {Object.keys(cards.aio.subNotes).map((note, i) => {
                        return (
                          <li
                            key={`${note}`}
                            className="note"
                            dangerouslySetInnerHTML={{
                              __html: cards.aio.notes[note],
                            }}
                          />
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            )}

            {/* POS */}
            {cards.pos && (
              <div className={` full-height price-card black pos-card mx-4`}>
                <div className="inner">
                  <div className="price-card-head">
                    <h3 className="title is-spaced text-black">
                      {cards.pos.title}
                    </h3>
                  </div>
                  <p className=" is-spaced text-black description">
                    {cards.pos.text}
                  </p>
                  <Prices
                    price={cards.pos.price}
                    note={cards.pos['price-note']}
                    campaign_prices={campaign_prices.pos}
                    group={priceGroup}
                  />
                  <Button
                    to={getPageUrl(cards.pos.key, lang).url}
                    className="purple cta"
                    primary
                    size="inline"
                  >
                    {cards.pos.ctaText}
                  </Button>

                  {cards.pos.notes && (
                    <ul className="price-notes">
                      {Object.keys(cards.pos.notes).map((note) => {
                        return (
                          <li
                            key={`${note}`}
                            className="note"
                            dangerouslySetInnerHTML={{
                              __html: cards.pos.notes[note],
                            }}
                          />
                        );
                      })}
                    </ul>
                  )}

                  <ul className="price-features">
                    {Object.keys(cards.pos.features).map((feature, i) => {
                      return (
                        <li key={`${feature}`} className="feature_included">
                          <Image
                            name="check_black.svg"
                            className="inline-svg check_black "
                            alt="shore.com"
                          />
                          {cards.pos.features[feature]}
                        </li>
                      );
                    })}
                  </ul>

                  {cards.pos.subNotes && (
                    <ul className="price-subnotes">
                      {Object.keys(cards.pos.subNotes).map((note, i) => {
                        return (
                          <li
                            key={`${note}`}
                            className="subNote"
                            dangerouslySetInnerHTML={{
                              __html: cards.pos.subNotes[note],
                            }}
                          />
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            )}

            {/* B&M */}
            <div className={`full-height price-card black b&m mx-4 `}>
              <div className="inner">
                <div className="price-card-head">
                  <h3 className="title is-spaced text-black">
                    {cards['b&m'].title}
                  </h3>
                </div>
                <p className=" is-spaced text-black description">
                  {cards['b&m'].text}
                </p>
                <Prices
                  price={cards['b&m'].price}
                  note={cards['b&m']['price-note']}
                  campaign_prices={campaign_prices['b&m']}
                  group={priceGroup}
                />
                <Button
                  to={getPageUrl(cards['b&m'].key, lang).url}
                  className="purple cta"
                  primary
                  size="inline"
                >
                  {cards['b&m'].ctaText}
                </Button>

                {cards['b&m'].notes && (
                  <ul className="price-notes blue">
                    {Object.keys(cards['b&m'].notes).map((note) => {
                      return (
                        <li
                          key={`${note}`}
                          className="note"
                          dangerouslySetInnerHTML={{
                            __html: cards['b&m'].notes[note],
                          }}
                        />
                      );
                    })}
                  </ul>
                )}

                <ul className="price-features">
                  {Object.keys(cards['b&m'].features).map((feature, i) => {
                    return (
                      <li key={`${feature}`} className="feature_included">
                        <Image
                          name="circle_plus_white.svg"
                          className="inline-svg circle-plus "
                          alt="shore.com"
                        />
                        {cards['b&m'].features[feature]}
                      </li>
                    );
                  })}
                </ul>

                {cards['b&m'].subNotes && (
                  <ul className="price-subnotes">
                    {Object.keys(cards.subNotes).map((note, i) => {
                      return (
                        <li
                          key={`${note}`}
                          className="subNote"
                          dangerouslySetInnerHTML={{
                            __html: cards.subNotes[note],
                          }}
                        />
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          </DefaultSlider>

          <div className="columns is-centered is-hidden-mobile">
            <div className="column  is-6 is-half   pricing-card-x2 ">
              {/* BOOKING */}
              <div className="price-card full-height black booking_card">
                <div className="inner">
                  <div className="price-card-head">
                    <h3 className="title is-spaced text-black">
                      {cards.booking.title}
                    </h3>
                  </div>
                  <p className=" is-spaced text-black description">
                    {cards.booking.text}
                  </p>
                  <Prices
                    price={cards.booking.price}
                    note={cards.booking['price-note']}
                    campaign_prices={campaign_prices.booking}
                    group={priceGroup}
                  />
                  <Button
                    to={getPageUrl(cards.booking.key, lang).url}
                    className="purple cta"
                    primary
                    size="inline"
                  >
                    {cards.booking.ctaText}
                  </Button>

                  {cards.booking.notes && (
                    <ul className="price-notes">
                      {Object.keys(cards.booking.notes).map((note) => {
                        return (
                          <li
                            key={`${note}`}
                            className="note"
                            dangerouslySetInnerHTML={{
                              __html: cards.booking.notes[note],
                            }}
                          />
                        );
                      })}
                    </ul>
                  )}

                  <ul className="price-features">
                    {Object.keys(cards.booking.features).map((feature, i) => {
                      return (
                        <li key={`${feature}`} className="feature_included">
                          <Image
                            name="check_black.svg"
                            className="inline-svg check_black "
                            alt="shore.com"
                          />
                          {cards.booking.features[feature]}
                        </li>
                      );
                    })}
                  </ul>

                  {cards.booking.subNotes && (
                    <ul className="price-subnotes">
                      {Object.keys(cards.booking.subNotes).map((note, i) => {
                        return (
                          <li key={`${note}`} className="subNote">
                            {cards.booking.subNotes[note]}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>

              {/* B&M */}
              <div className={`full-height price-card black b&m-card `}>
                <div className="inner">
                  <div className="price-card-head">
                    <h3 className="title is-spaced text-black">
                      {cards['b&m'].title}
                    </h3>
                  </div>
                  <p className=" is-spaced text-black description">
                    {cards['b&m'].text}
                  </p>

                  <Prices
                    price={cards['b&m'].price}
                    note={cards['b&m']['price-note']}
                    campaign_prices={campaign_prices['b&m']}
                    group={priceGroup}
                  />
                  <Button
                    to={getPageUrl(cards['b&m'].key, lang).url}
                    className="purple cta"
                    primary
                    size="inline"
                  >
                    {cards['b&m'].ctaText}
                  </Button>

                  {cards['b&m'].notes && (
                    <ul className="price-notes blue">
                      {Object.keys(cards['b&m'].notes).map((note) => {
                        return (
                          <li
                            key={`${note}`}
                            className="note"
                            dangerouslySetInnerHTML={{
                              __html: cards['b&m'].notes[note],
                            }}
                          />
                        );
                      })}
                    </ul>
                  )}

                  <ul className="price-features">
                    {Object.keys(cards['b&m'].features).map((feature, i) => {
                      return (
                        <li key={`${feature}`} className="feature_included">
                          <Image
                            name="circle_plus_white.svg"
                            className="inline-svg circle-plus "
                            alt="shore.com"
                          />
                          {cards['b&m'].features[feature]}
                        </li>
                      );
                    })}
                  </ul>

                  {cards['b&m'].subNotes && (
                    <ul className="price-subnotes">
                      {Object.keys(cards['b&m'].subNotes).map((note, i) => {
                        return (
                          <li key={`${note}`} className="subNote">
                            {cards['b&m'].subNotes[note]}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </div>

            {/* POS */}
            {cards.pos && (
              <div className="column p-0 is-one-quarter ">
                <div
                  className={`column full-height price-card pt-0 pr-0 black pos-card `}
                >
                  <div className="inner">
                    <div className="price-card-head">
                      <h3 className="title is-spaced text-black">
                        {cards.pos.title}
                      </h3>
                    </div>
                    <p className="is-spaced text-black description">
                      {cards.pos.text}
                    </p>
                    <Prices
                      price={cards.pos.price}
                      note={cards.pos['price-note']}
                      campaign_prices={campaign_prices.pos}
                      group={priceGroup}
                    />
                    <Button
                      to={getPageUrl(cards.pos.key, lang).url}
                      className="purple cta"
                      primary
                      size="inline"
                    >
                      {cards.pos.ctaText}
                    </Button>

                    {cards.pos.notes && (
                      <ul className="price-notes">
                        {Object.keys(cards.pos.notes).map((note) => {
                          return (
                            <li
                              key={`${note}`}
                              className="note"
                              dangerouslySetInnerHTML={{
                                __html: cards.pos.notes[note],
                              }}
                            />
                          );
                        })}
                      </ul>
                    )}

                    <ul className="price-features">
                      {Object.keys(cards.pos.features).map((feature, i) => {
                        return (
                          <li key={`${feature}`} className="feature_included">
                            <Image
                              name="check_black.svg"
                              className="inline-svg check_black "
                              alt="shore.com"
                            />
                            {cards.pos.features[feature]}
                          </li>
                        );
                      })}
                    </ul>

                    {cards.pos.subNotes && (
                      <ul className="price-subnotes">
                        {Object.keys(cards.pos.subNotes).map((note, i) => {
                          return (
                            <li key={`${note}`} className="note">
                              <Image
                                name="circle_info.svg"
                                className="inline-svg circle-info white"
                                alt="shore.com"
                              />
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: cards.pos.subNotes[note],
                                }}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* AIO */}
            {cards.aio && (
              <div className="column p-0 is-one-quarter ">
                <div className="column full-height price-card black pt-0 pr-0 aio-card">
                  {cards.aio.badge && (
                    <div className="badge background-new-green text-white">
                      {cards.aio.badge}
                    </div>
                  )}
                  <div className="inner aio">
                    <div className="price-card-head">
                      <h3 className="title is-spaced text-black">
                        {cards.aio.title}
                      </h3>
                    </div>
                    <p className="is-spaced text-black description">
                      {cards.aio.text}
                    </p>

                    <Prices
                      price={cards.aio.price}
                      note={cards.aio['price-note']}
                      campaign_prices={campaign_prices.aio}
                      group={priceGroup}
                    />
                    <Button
                      to={getPageUrl(cards.aio.key, lang).url}
                      className={cards.pos.ctaColor}
                      primary={cards.pos.ctaColor === 'purple'}
                      size="inline"
                    >
                      {cards.aio.ctaText}
                    </Button>

                    {cards.aio.notes && (
                      <ul className="price-notes green">
                        {Object.keys(cards.aio.notes).map((note) => {
                          return (
                            <li
                              key={`${note}`}
                              className="note"
                              dangerouslySetInnerHTML={{
                                __html: cards.aio.notes[note],
                              }}
                            />
                          );
                        })}
                      </ul>
                    )}

                    <ul className="price-features aio">
                      <li className="features_title">
                        {cards.aio.features_title}
                      </li>
                      {Object.keys(cards.aio.features).map((feature, i) => {
                        return (
                          <li key={`${feature}`} className="feature_included">
                            <Image
                              name="circle_plus_white.svg"
                              className="inline-svg circle-plus green"
                              alt="shore.com"
                            />
                            {cards.aio.features[feature]}
                          </li>
                        );
                      })}
                    </ul>

                    {cards.aio.subNotes && (
                      <ul className="price-subnotes">
                        {Object.keys(cards.aio.subNotes).map((note, i) => {
                          return (
                            <li key={`${note}`} className="note">
                              <Image
                                name="circle_info.svg"
                                className="inline-svg circle-info white"
                                alt="shore.com"
                              />
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: cards.aio.subNotes[note],
                                }}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="container is-boxed is-centered text-centered mt-5 pt-3  discover_more">
          { lang !== 'de' && lang !== 'de-CH' ? null : data('disclaimer') !== 'disclaimer' && (
            <p className="mt-5 small-note has-text-black">
              *Shore Pay ist aktuell nur in Deutschland erhältlich.
            </p>
          ) }
          <a href="#pricing_packages_section" className="mt-5">
            {data('cardsCta')}
            <Image
              name="angle-right.svg"
              alt="angle-right icon"
              className="inline-icon angle inline"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default PricingCards;
