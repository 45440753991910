import * as React from 'react';
import { FC, useState } from 'react';
import { Image } from '../ui/image';

const SectionFaq: FC<{
  title: string;
  classes: string;
  questions: any;
}> = ({ title, questions, classes }) => {
  const [toggle, setToggle] = useState('');

  return (
    <section className={`${classes} section section-faq`}>
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8 ">
            <h2 className="title title-large has-text-centered text-black">
              {title}
            </h2>
          </div>
        </div>
        <div className=" is-centered columns">
          <div className="column is-10 questions ">
            {Object.keys(questions).map((key) => {
              return (
                <div key={key} className={`${key} faq_entrie`}>
                  <h3 className="question">
                    <button
                      onClick={() => setToggle(toggle === key ? '' : key)}
                      type="button"
                      onKeyDown={() => setToggle(toggle === key ? '' : key)}
                    >
                      {questions[key].title}

                      {toggle === key ? (
                        <Image
                          name="arrow_up.svg"
                          className="inline-svg"
                          alt="arrow"
                        />
                      ) : (
                        <Image
                          name="arrow_down.svg"
                          className="inline-svg"
                          alt="arrow"
                        />
                      )}
                    </button>
                  </h3>
                  {toggle === key && (
                    <div
                      className="answer"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: questions[key].text }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionFaq;
