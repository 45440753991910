/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { FC } from 'react';
import TrustPilot from '../widgets/TrustPilot';

const Quote: FC<{
  quote: any;
}> = ({ quote }) => {
  return (
    <section className="shop-quote-section section background-new-grey">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-9 ">
            <p
              className="shop-quote"
              dangerouslySetInnerHTML={{ __html: quote }}
            />
          </div>
        </div>
        <div className="columns is-centered is-vcentered text-centered">
          <div className="column is-9 is-centered is-vcentered  text-centered">
            <div className="trust-box">
              <TrustPilot />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Quote;
