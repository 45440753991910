/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

interface StyledProps {
  block?: string;
  height?: number;
  size?: string;
  group?: string;
  className?: any;
  before?: string;
  after?: string;
}

const NewStyle = styled.span<StyledProps>`
  position: relative;
  width: 300px;
  height: 45px;
  border-radius: 20px;
  margin: 20px 0;

  position: relative;
  width: 300px;
  height: 37px;
  border-radius: 20px;
  margin: 20px 0;
  &:hover {
    cursor: pointer;
  }
  ${(props: any) => `
      input:before { content: '${props.before}' }
      input:after { content: '${props.after}' }
    `}
  input {
    appearance: none;
    position: relative;
    width: 264px;
    height: 45px;
    background-color: #fff;
    outline: none;
    border-radius: 23.5px;
    border: solid 2px #2ec671;
    background-color: #fff;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;

    &:before,
    &:after {
      -moz-appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
      z-index: 2;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      text-align: center;
      color: #fff;
      font-family: 'Nunito Sans';
      font-size: 14px;
    }

    &:before {
      left: 4px;
      width: 90px;
      transition: color 0.5s 0.2s;
    }

    &:after {
      right: 0px;
      width: 160px;
    }
  }

  label {
    z-index: 1;
    position: absolute;
    top: -24px;
    bottom: 10px;
    border-radius: 20px;
    height: 35px;
    width: 90px;
  }

  &.price-switcher {
    input {
      transition: 0.25s -0.1s;

      &:checked {
        background-color: #fff;

        &:before {
          color: #f6f7fa; //f6f7fa;
          transition: color 0.5s 0.2s;
        }

        &:after {
          color: #2e2f35; // 2e2f35;
          transition: color 0.5s;
        }

        & + label {
          left: 6px;
          right: 150px;
          background: #2e2f35;
          color: #f6f7fa;
          transition: all 0.6s 0.2s, left 0.2s 0.2s;
        }
      }

      &:not(:checked) {
        background: #fff;
        transition: all 0.5s -0.1s;

        &:before {
          color: #2e2f35;
          transition: color 0.5s;
        }

        &:after {
          color: #f6f7fa; //2e2f35;
          transition: color 0.5s 0.2s;
        }

        & + label {
          left: 99px;
          width: 160px;
          right: 1px;
          background: #2e2f35;
          color: #f6f7fa;
          transition: all 0.5s, left 0.4s 0.2s;
        }
      }
    }
  }
`;

const SwitchSecondary: FC<{
  setPriceGroup: any;
  current: string;
}> = ({ setPriceGroup, current }) => {
  const { t } = useTranslation('pricing');
  const data = {
    month: t('monthly'),
    year: t('yearly'),
  };
  return (
    <div className="Switch version_two text-centered pb-5">
      <NewStyle
        group={current}
        before={data.month}
        after={data.year}
        className="switcher price-switcher"
      >
        <input
          type="checkbox"
          id="price-switcher"
          onChange={() => setPriceGroup(current === 'year' ? 'month' : 'year')}
        />
        <label htmlFor="price-switcher" />
      </NewStyle>
    </div>
  );
};

const SwitchStyle = styled.span<StyledProps>`
  position: relative;
  width: 300px;
  height: 37px;
  border-radius: 20px;
  margin: 20px 0;

  position: relative;
  width: 300px;
  height: 37px;
  border-radius: 20px;
  margin: 20px 0;
  &:hover {
    cursor: pointer;
  }
  ${(props: any) => `
      input:before { content: '${props.before}' }
      input:after { content: '${props.after}' }
    `}
  input {
    appearance: none;
    position: relative;
    width: 300px;
    height: 37px;
    border-radius: 20px;
    background-color: #222;
    outline: none;
    border-radius: 20px;
    border: solid 1px rgba(189, 195, 199, 0.6);
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;

    &:before,
    &:after {
      -moz-appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
      z-index: 2;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 150px;
      text-align: center;
      color: $white;
      font-family: 'Nunito Sans';
      font-size: 17px;
    }

    &:before {
      left: 0px;
      transition: color 0.5s 0.2s;
    }

    &:after {
      right: 0px;
      width: 160px;
    }
  }

  label {
    z-index: 1;
    position: absolute;
    top: -20px;
    bottom: 10px;
    border-radius: 20px;
    height: 35px;
  }

  &.price-switcher {
    input {
      transition: 0.25s -0.1s;

      &:checked {
        background-color: #f6f7fa; //f6f7fa;

        &:before {
          color: #f6f7fa; //f6f7fa;
          transition: color 0.5s 0.2s;
        }

        &:after {
          color: #2e2f35; // 2e2f35;
          transition: color 0.5s;
        }

        & + label {
          left: 0px;
          right: 150px;
          background: #2e2f35;
          color: #f6f7fa;
          transition: right 0.6s 0.2s, left 0.2s 0.2s;
        }
      }

      &:not(:checked) {
        background: #f6f7fa;
        transition: all 0.5s -0.1s;

        &:before {
          color: #2e2f35;
          transition: color 0.5s;
        }

        &:after {
          color: #f6f7fa; //2e2f35;
          transition: color 0.5s 0.2s;
        }

        & + label {
          left: 130px;
          right: 1px;
          background: #2e2f35;
          color: #f6f7fa;
          transition: right 0.5s, left 0.4s 0.2s;
        }
      }
    }
  }
`;

const SwitchPrimary: FC<{
  setPriceGroup: any;
  current: string;
}> = ({ setPriceGroup, current }) => {
  const { t } = useTranslation('pricing');
  const data = {
    month: t('monthly'),
    year: t('yearly'),
  };
  return (
    <div className="Switch text-centered">
      <SwitchStyle
        group={current}
        before={data.month}
        after={data.year}
        className="switcher price-switcher"
      >
        <input
          type="checkbox"
          id="price-switcher"
          onChange={() => setPriceGroup(current === 'year' ? 'month' : 'year')}
        />
        <label htmlFor="price-switcher" />
      </SwitchStyle>
    </div>
  );
};
export { SwitchSecondary };
export default SwitchPrimary;
