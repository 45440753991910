/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import { isMobile, isMobileOnly, isDesktop } from 'react-device-detect';
import { Image } from '../../ui/image';
import { ChildSlider } from '../common/elements/Slider';

const Shop: FC<{
  data: any;
  title: any;
}> = ({ title, data }) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([
    {
      name: 'Shore Complete',
      permalink: 'https://shop.shore.com/shop/shore-complete/',
      prices: {
        price: '149900',
        regular_price: '149900',
        currency_symbol: '€',
      },
      badge: 'All in One',
      images: {
        '0': {
          alt: '',
          src:
            'https://shop.shore.com/wp-content/uploads/2023/01/shore_bundles_pos_03a.jpg',
          srcset:
            'https://shop.shore.com/wp-content/uploads/2023/01/shore_bundles_pos_03a.jpg',
        },
      },
    },
    {
      name: 'Shore Kartenlesegerät',
      permalink: 'https://shop.shore.com/shop/shore-pay',
      prices: {
        price: '6900',
        regular_price: '6900',
        currency_symbol: '€',
      },
      badge: 'Unsere Empfehlung',
      images: {
        '0': {
          alt: 'Unser Shore Pay Kartenlesegerät',
          src:
            'https://shop.shore.com/wp-content/uploads/2023/01/shore_pay_01c_new.jpg',
          srcset:
            'https://shop.shore.com/wp-content/uploads/2023/01/shore_pay_01c_new.jpg',
        },
      },
    },
    {
      name: 'Shore Dock',
      permalink: 'https://shop.shore.com/shop/shore-pay-dock/',
      prices: {
        price: '4900',
        regular_price: '4900',
        currency_symbol: '€',
      },
      badge: 'Neu',
      images: {
        '0': {
          alt: '',
          src:
            'https://shop.shore.com/wp-content/uploads/2023/01/shore_dock_02a.jpg',
          srcset:
            'https://shop.shore.com/wp-content/uploads/2023/01/shore_dock_02a.jpg',
        },
      },
    },
  ]);

  /*  useEffect(() => {
    fetch('https://shop.shore.com/wp-json/wc/store/products')
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          const stock = result.filter((d) => d.is_in_stock === true);
          setItems(stock.sort(() => Math.random() - Math.random()).slice(0, 3));
        },
        // eslint-disable-next-line no-shadow
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);
 */
  return (
    <section
      id="store-cards-section"
      className={` shop-products-section section store-cards-section background-new-grey is-hidden-mobile`}
    >
      <div className="store-cards  is-boxed is-centered">
        <div className="container">
          <div className="section-head">
            <h3
              className="title title-large section-title text-black has-text-centered"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
          <div className="columns is-hidden-desktop">
            <ChildSlider>
              {Object.keys(items).map((key) => {
                const card = items[key];
                return card ? (
                  <div
                    key={key}
                    className="column full-height price-card is-4 "
                  >
                    <a href={card.permalink} target="_blank" rel="noreferrer">
                      <div className="card hardware_showcase card-equal-height">
                        <div className="card-image">
                          <figure className="image is-4by3">
                            <img
                              srcSet={card.images[0].scrset}
                              src={card.images[0].src}
                              alt={card.images[0].alt}
                            />
                          </figure>
                        </div>

                        <span className="green-badge extra-text">
                          {card.badge}
                        </span>

                        <h4 className="has-text-centered product-title">
                          {card.name}
                        </h4>

                        <div className="card-content">
                          <div className="content has-text-centered">
                            <div className="product-price ">
                              {card.prices.regular_price.substring(
                                0,
                                card.prices.regular_price.length - 2
                              )}
                              {card.prices.currency_symbol}
                            </div>
                            <p className="hidden">
                              zzgl. gesetzlich geltender MwSt.
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                ) : null;
              })}{' '}
            </ChildSlider>
          </div>

          {!isMobile && (
            <div className="columns ">
              {Object.keys(items).map((key) => {
                const card = items[key];
                return (
                  <div
                    key={key}
                    className="column full-height price-card is-4 "
                  >
                    <a href={card.permalink}>
                      <div className="card hardware_showcase card-equal-height">
                        <div className="card-image">
                          <figure className="image is-4by3">
                            <img
                              srcSet={card.images[0].scrset}
                              src={card.images[0].src}
                              alt={card.images[0].alt}
                            />
                          </figure>
                        </div>
                        <div className="card-body">
                          <span className="green-badge extra-text">
                            {card.badge}
                          </span>

                          <h4 className="has-text-centered product-title">
                            {card.name}
                          </h4>

                          <div className="card-content">
                            <div className="content has-text-centered">
                              <div className="product-price ">
                                {card.prices.regular_price.substring(
                                  0,
                                  card.prices.regular_price.length - 2
                                )}
                                {card.prices.currency_symbol}
                              </div>
                              <p className="hidden">
                                zzgl. gesetzlich geltender MwSt.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}{' '}
            </div>
          )}
        </div>
        <div className="shop-link">
          <a href="https://shop.shore.com" target="_blank" rel="noreferrer">
            Mehr Kassenzubehör{' '}
            <Image
              name="angle-right.svg"
              alt="angle-right icon"
              className="inline-icon angle inline"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Shop;
