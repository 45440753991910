import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageGallery } from '../../ui/imageGallery';

import Button from '../../ui/button';
import pages from '../../../utils/pages';

const { getPageUrl } = pages;

const EnterpriseCard: FC<{
  data: any;
  classes?: string;
}> = ({ data, classes }) => {
  const { t } = useTranslation('general');
  const Url = getPageUrl(data.ctaKey, t('key')).url;
  return (
    <section className="enter_section  background-new-grey ">
      <div className="container">
        <div className="has-background-pure-black columns is-vcentered enter-action mx-auto is-centered">
          <div className="column is-7 sections-title-left ">
            <h2 className="title title-medium text-white">{data.title}</h2>
            {data.text && <p className="text-white mb-5">{data.text}</p>}
            <Button color="exclusive" className="roi_cta" to={Url}>
              {data.ctaText}
            </Button>
          </div>
          <div className="column is-5  p-0">
            <ImageGallery
              name={data.image}
              alt={data.title}
              className="feature-image image-centered "
              maxWidth="1058"
              height="458"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

EnterpriseCard.defaultProps = {
  classes: ``,
};
export default EnterpriseCard;
